import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import useGetChannels from "../../../../firebase/useGetChannels";
import TabsContext from "../../../../context/tabsContext";
import useSubscribeChannelMessages from "../../../../firebase/useSubscribeChannelMessages";
import BottomBiggerCard from "../../../Cards/BottomBiggerCard";
import { InboxCardsWrapper } from "../styles";
import Inbox from "./Inbox";
import Axios from "../../../../serverConfig";
import getUserDetailUrl from "../../../../consts/getUserDetailUrl";
import { LoaderContainer } from "../../../TableUsers/styles";
import { Spin } from "antd";

interface IUserInfoMessage {
  id: string;
  concatChannelId: string;
  assignedUsers: number[];
  userInfo: any;
  text: any;
}

const InboxContainer = () => {
  const { handleChannelId } = useContext(TabsContext);

  const [userInfoMessage, setUserInfoMessage] = useState<IUserInfoMessage[]>(
    []
  );

  const params = useParams();
  const { userId: uid } = params;
  const parsedId = parseInt(uid!);

  const { channels, idChannels } = useGetChannels(uid!);

  const { channelMessages } = useSubscribeChannelMessages(idChannels);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!uid || !channelMessages.length) {
      return;
    }

    const processData = async () => {
      setIsLoading(true);
      const parsedUsers = channels.map((item) => ({
        ...item,
        assignedUsers: item.assignedUsers.filter((id) => id !== parsedId),
      }));

      const idFromParsedUsers = parsedUsers.map(
        (item) => item.assignedUsers[0]
      );

      const idFromParsedUsersFiltered = idFromParsedUsers.filter(
        (item) => item
      );

      if (!idFromParsedUsersFiltered) {
        return;
      }

      const pUserData = await Promise.allSettled(
        idFromParsedUsersFiltered?.map(async (item) => {
          const res = await Axios.get(getUserDetailUrl(item.toString()));
          return res.data;
        })
      );

      const usersData = pUserData
        .map((item) => (item.status === "fulfilled" ? item.value : null))
        .filter((item) => item);

      const channelWithUserInfo = parsedUsers.map((item) => ({
        ...item,
        userInfo: usersData.filter(({ id }: { id: number }) =>
          item.assignedUsers.includes(id)
        )[0],
        text: channelMessages.filter(
          ({ user }) => user._id === item.assignedUsers[0] || user._id === uid
        ),
      }));
      setUserInfoMessage(channelWithUserInfo);
      setIsLoading(false);
    };

    processData();
  }, [channels, idChannels, channelMessages, uid, parsedId]);

  if (!userInfoMessage) {
    return <p>No message</p>;
  }

  return (
    <BottomBiggerCard title={"Inbox"} overflow={true}>
      <InboxCardsWrapper>
        {isLoading ? (
          <LoaderContainer>
            <Spin size={"large"} />
          </LoaderContainer>
        ) : (
          userInfoMessage.map(({ userInfo, id, text }) => (
            <span key={id} onClick={() => handleChannelId(id)}>
              <Inbox
                channelId={id}
                userHandle={userInfo?.handle}
                userLogo={userInfo?.image}
                userName={`${userInfo?.firstName} ${userInfo?.lastName}`}
                textMessage={text[0]?.text}
                dateMessage={moment(text[0]?.createdAt.toDate())
                  .format("DD-MM-YYYY")
                  .toString()}
              />
            </span>
          ))
        )}
      </InboxCardsWrapper>
    </BottomBiggerCard>
  );
};
export default InboxContainer;
