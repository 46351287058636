import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIRE_API_KEY,
//     authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIRE_APP_ID,
// }

const firebaseConfig = {
  apiKey: "AIzaSyCsn41KDPsk9tQx86Sy8JRiw41bGZp8ZHo",
  authDomain: "joosup-8b250.firebaseapp.com",
  projectId: "joosup-8b250",
  storageBucket: "joosup-8b250.firebasestorage.app",
  messagingSenderId: "246977024931",
  appId: "1:246977024931:web:1058266a7b97c9d2ad8016",
};

const fireBaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(fireBaseApp);
export const db = getFirestore(fireBaseApp);
