import React, { useRef } from "react";
import { Form, message, Modal, Switch } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ValidationEditChargerInformation from "../../../validationSchema/ValidationEditChargerInformation";
import { Label, RowModal } from "../../TextInput/TextInputRow/styles";
import createChargerTypeList from "../../../helpers/createChargerTypeList";
import createChargerRateList from "../../../helpers/createChargerRateList";
import { IChargerDetails } from "../../../types/rest/charger/IChargerDetails";
import Axios from "../../../serverConfig";
import { getEditCharger } from "../../../consts/getEditCharger";
import { TextInputRow } from "../../index";
import { DividerModal } from "../../Divider";
import {
  ButtonSubmit,
  ButtonsWrapper,
  CancelButton,
  Row,
  SelectCar,
} from "../styles";

interface IProps {
  handleToggle: () => void;
  toggleOpenEditor: boolean;
  setChargerInfo: React.Dispatch<
    React.SetStateAction<IChargerDetails | undefined>
  >;
  chargerInfo: IChargerDetails;
}

const chargerTypeList = createChargerTypeList();
const chargerRateList = createChargerRateList();

const EditChargerInformation: React.FC<IProps> = ({
  handleToggle,
  toggleOpenEditor,
  setChargerInfo,
  chargerInfo,
}) => {
  const { t } = useTranslation();

  const errorMessage = () => message.error("Sorry something going wrong!");
  const successEditMessage = () =>
    message.success("Success.Charger information changed");

  const initialValues = {
    ...chargerInfo,
  };
  const formikRef = useRef<any>();

  const handleFormikClick = () => {
    if (formikRef?.current !== null) {
      formikRef.current.handleSubmit();
    }
  };

  const handleSubmitForm = async (values: IChargerDetails) => {
    if (!values) {
      return;
    }
    try {
      const { ratings, ...rest } = values;
      const body: IChargerDetails = {
        ...rest,
        chargerRate: rest.chargerRate.replace("kW", "").trim(),
      };

      await Axios.post(getEditCharger(), body);
      successEditMessage();
      setChargerInfo({
        ...values,
        chargerRate: values.chargerRate.replace("kW", "").trim(),
      });
      handleToggle();
    } catch (e: unknown) {
      errorMessage();
    }
  };

  return (
    <Modal
      width={600}
      mask={true}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Edit Charger Information"
      centered
      visible={toggleOpenEditor}
      onOk={handleFormikClick}
      onCancel={handleToggle}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={ValidationEditChargerInformation}
        onSubmit={handleSubmitForm}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form
            onFinish={handleSubmit}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name={"type"}>
              <RowModal>
                <Label>{t("type")}</Label>
                <SelectCar
                  name={"type"}
                  value={values.type}
                  onChange={handleChange}
                >
                  {chargerTypeList.map((item) => {
                    if (item === chargerInfo.type) {
                      return (
                        <option value={chargerInfo.type} selected>
                          {item[0].toUpperCase() + item.substring(1)}
                        </option>
                      );
                    }
                    return (
                      <option value={item}>
                        {item[0].toUpperCase() + item.substring(1)}
                      </option>
                    );
                  })}
                </SelectCar>
              </RowModal>
            </Form.Item>
            <Form.Item name={"chargerRate"}>
              <RowModal>
                <Label>{t("chargerRate")}</Label>
                <SelectCar
                  name={"chargerRate"}
                  value={values.chargerRate}
                  onChange={handleChange}
                >
                  {chargerRateList.map((item) => {
                    if (item === chargerInfo.chargerRate) {
                      return (
                        <option value={item} selected>
                          {item[0].toUpperCase() + item.substring(1)}
                        </option>
                      );
                    }
                    return (
                      <option value={item}>
                        {item[0].toUpperCase() + item.substring(1)}
                      </option>
                    );
                  })}
                </SelectCar>
              </RowModal>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item name={"tethered"}>
              <Row>
                <Label>{t("tethered")}</Label>
                <Switch
                  defaultChecked={values.tethered}
                  onChange={(value: boolean) =>
                    setFieldValue("tethered", value)
                  }
                />
              </Row>
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item>
              <TextInputRow
                addonAfter={"£"}
                labelText={t("hourlyRate")}
                name={"hourlyRateFee"}
                value={values.hourlyRateFee}
                handleChange={handleChange}
                errors={errors.hourlyRateFee}
                touched={touched.hourlyRateFee}
              />
            </Form.Item>
            <Form.Item>
              <TextInputRow
                addonAfter={"£"}
                labelText={t("minimumSessionFee")}
                name={"minimumSessionFee"}
                value={values.minimumSessionFee}
                handleChange={handleChange}
                errors={errors.minimumSessionFee}
                touched={touched.minimumSessionFee}
              />
            </Form.Item>
            <DividerModal bottom={"15px"} top={"15px"} />
            <Form.Item>
              <TextInputRow
                labelText={t("additionalInformation")}
                name={"chargerAdditionalInformation"}
                value={values.chargerAdditionalInformation!}
                handleChange={handleChange}
                errors={errors.chargerAdditionalInformation}
                touched={touched.chargerAdditionalInformation}
              />
            </Form.Item>
            <ButtonsWrapper>
              <CancelButton onClick={handleToggle}>Cancel</CancelButton>
              <ButtonSubmit htmlType="submit">Save</ButtonSubmit>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditChargerInformation;
